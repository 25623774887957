/**
 * Group actions
 */
export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_REMOVE = 'CLIENT_REMOVE';

export function clientUpdate(value) {
    return { type: CLIENT_UPDATE, value };
};

export function clientRemove() {
    return { type: CLIENT_REMOVE };
};