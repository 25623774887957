import { PATIENT_SET, PATIENT_UPDATE, PATIENT_UPDATE_SETTINGS, PATIENT_REMOVE, PATIENT_ACCESS_KEY_SET, PATIENT_ACCESS_KEY_REMOVE, PATIENT_LOGGED_IN, PATIENT_LOGGED_OUT } from './patient.actions';

const patientReducer = (state = null, action) => {
    switch (action.type) {
        case PATIENT_LOGGED_IN:
            return action.value;
        case PATIENT_LOGGED_OUT:
            return null;
        case PATIENT_SET:
        case PATIENT_UPDATE:
            return {
                ...state,
                [action.name]: action.value
            }
        case PATIENT_REMOVE:
            return {
                ...state,
                [action.name]: undefined
            }
        case PATIENT_UPDATE_SETTINGS:
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    settings: action.value
                }
            }
        case PATIENT_ACCESS_KEY_SET:
            return {
                ...state,
                access_key: action.value
            }
        case PATIENT_ACCESS_KEY_REMOVE:
            return {
                ...state,
                access_key: undefined
            }
        default:
            return state;
    }
}

export default patientReducer;