import { API } from '../../services/api';
import { PATIENT_LOGGED_IN, PATIENT_LOGGED_OUT, PATIENT_ACCESS_KEY_REMOVE } from './patient.actions';

/*
   Hook into setting changes in order to change layout.
*/
const patient = store => next => action => {
    let result = next(action)

    if (action.type === PATIENT_LOGGED_IN) {
        API.setToken(action.value.access_key);
    } else if (action.type === PATIENT_LOGGED_OUT || action.type === PATIENT_ACCESS_KEY_REMOVE) {
        API.setToken(null);
    }

    return result
}

export default patient;