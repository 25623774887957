/**
 * Patient actions
 */
export const PATIENT_LOGGED_IN = 'PATIENT_LOGGED_IN';
export const PATIENT_LOGGED_OUT = 'PATIENT_LOGGED_OUT';

export function patientLoggedIn(value) {
    return { type: PATIENT_LOGGED_IN, value };
};

export function patientLoggedOut() {
    return { type: PATIENT_LOGGED_OUT };
};

export const PATIENT_SET = 'PATIENT_SET';
export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const PATIENT_UPDATE_SETTINGS = 'PATIENT_UPDATE_SETTINGS';
export const PATIENT_REMOVE = 'PATIENT_REMOVE';

export function patientSet(name, value) {
    return { type: PATIENT_SET, name, value };
};

export function patientUpdate(name, value) {
    return { type: PATIENT_UPDATE, name, value };
};

export function patientUpdateSettings(name, value) {
    return { type: PATIENT_UPDATE_SETTINGS, name, value };
};

export function patientRemove(name) {
    return { type: PATIENT_REMOVE, name };
};

/**
 * Patient Access key actions
 */
export const PATIENT_ACCESS_KEY_SET = 'PATIENT_PATIENT_ACCESS_KEY_SET';
export const PATIENT_ACCESS_KEY_REMOVE = 'PATIENT_PATIENT_ACCESS_KEY_REMOVE';

export function patientAccessKeySet(value) {
    return { type: PATIENT_ACCESS_KEY_SET, value };
};

export function patientAccessKeyRemove() {
    return { type: PATIENT_ACCESS_KEY_REMOVE };
};